<template>
  <transition name="home">
    <div class="fade-in-bottom" :class="{'show': showHome}">
      <header-view :page-active="'home'"/>

      <div class="main-slide mt-8 xl:mt-10 flex flex-col-reverse xl:flex-row">
        <div class="w-full xl:w-3/5 xl:text-left flex flex-col justify-between mt-12 xl:mt-0">
          <div>
            <p class="text-2xl">Software Developer</p>
            <h1 class="text-6xl font-medium mt-2">Hello I'm</h1>
            <h1 class="text-6xl primary-color font-medium mt-2">Kahn Potter</h1>
            <p class="mt-5">Passionate about exploring and embracing new technologies.</p>
          </div>

          <div class="flex mt-8 xl:mt-0 justify-center xl:justify-normal">
            <div class="flex btn-download-cv rounded-full p-3 ps-8 pe-8 cursor-pointer" @click="downloadCV">
              <button class="primary-color me-3">
                DOWNLOAD CV
              </button>
              <svg class="h-6 w-6 text-purple-500 primary-color" width="24" height="24" viewBox="0 0 24 24"
                   stroke-width="2"
                   stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"/>
                <polyline points="7 11 12 16 17 11"/>
                <line x1="12" y1="4" x2="12" y2="16"/>
              </svg>

            </div>

            <a href="https://gitlab.com/loc.ngvan.dev" target="_blank" title="Gitlab">
              <button class="btn-download-cv rounded-full p-4 cursor-pointer ms-8">
                <svg class="h-6 w-6 text-purple-500 primary-color" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2"
                     stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <path d="M21 14l-9 7-9-7L6 3l3 7h6l3-7z"/>
                </svg>
              </button>
            </a>

            <a href="https://www.linkedin.com/in/loc-ngvan-dev" target="_blank" title="LinkedIn">
              <button class="btn-download-cv rounded-full p-4 cursor-pointer ms-4">
                <svg class="h-6 w-6 text-purple-500 primary-color" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/>
                  <rect x="2" y="9" width="4" height="12"/>
                  <circle cx="4" cy="4" r="2"/>
                </svg>
              </button>
            </a>
          </div>
        </div>


        <div class="w-full xl:w-2/5 aspect-square relative">
          <div class="w-full h-full avatar-border rounded-full absolute"></div>
          <div class="w-full h-full absolute">
            <img src="https://kahnpotter.s3.ap-east-1.amazonaws.com/avatar.JPG" alt="" class=" w-full h-full p-2 rounded-full object-cover">
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-16">
        <div class="w-1/4 flex justify-center">
          <div class="text-6xl font-extrabold">+2</div>
          <div class="flex-wrap text-left w-min ms-2 content-center">Years of experience</div>
        </div>
        <div class="w-1/4 flex justify-center">
          <div class="text-6xl font-extrabold">+4</div>
          <div class="flex-wrap text-left w-min ms-2 content-center">Projects experience</div>
        </div>
        <div class="w-1/4 flex justify-center">
          <div class="text-6xl font-extrabold">+8</div>
          <div class="flex-wrap text-left w-min ms-2 content-center">Technologies known</div>
        </div>
        <div class="w-1/4 flex justify-center">
          <div class="text-6xl font-extrabold">+500</div>
          <div class="flex-wrap text-left w-min ms-2 content-center">Code commits</div>
        </div>
      </div>

      <div class="justify-between mt-10">
        <div class="text-2xl text-left mb-3">Tech stack</div>
        <div class="flex flex-wrap justify-center gap-4">
          <div class="h-16">
            <img class="h-full rounded-full object-center" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/vue.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-center" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/spring.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-center" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/kotlin.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/js.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/git.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/docker.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/aws.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/mongodb.png" alt="">
          </div>
          <div class="h-16">
            <img class="h-full rounded-full object-contain" src="https://kahnpotter.s3.ap-east-1.amazonaws.com/jira.png" alt="">
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, ref} from 'vue';
import HeaderView from "@/components/HeaderView.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderView
  },
  setup() {
    const showHome = ref(false)

    onMounted(() => {
      setTimeout(async () => {
        showHome.value = true;
        await nextTick();
      }, 800); // Hiển thị component sau 1 giây
    });

    const downloadCV = function () {
      console.log(111)
    }

    return {
      showHome,
      downloadCV
    }
  }
});
</script>

<style lang="scss" scoped>

.btn-download-cv {
  border: 2px solid var(--main-color);
}

.avatar-border {
  border: 2px dashed var(--main-color);
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
</style>
