import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navigation relative flex justify-between py-6 px-2 xl:px-0" }
const _hoisted_2 = { class: "nav-group-btn hidden xl:flex" }
const _hoisted_3 = {
  key: 0,
  class: "mobile-btn-grp"
}

import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderView',
  props: {
    pageActive: {}
  },
  setup(__props: any) {

const props = __props;

const isShowGrpBtnNavMobile = ref(false)

function showGrpBtnNavMobile() {
  isShowGrpBtnNavMobile.value = true
}

function hiddenGrpBtnNavMobile() {
  isShowGrpBtnNavMobile.value = false
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "italic text-4xl" }, "KahnPotter", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'page-active': props.pageActive === 'home' })
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("a", { href: "/" }, "Home", -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass({ 'page-active': props.pageActive === 'services' })
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("a", { href: "/services" }, "Services", -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass({ 'page-active': props.pageActive === 'resume' })
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("a", { href: "/resume" }, "Resume", -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass({ 'page-active': props.pageActive === 'work' })
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("a", { href: "/work" }, "Work", -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass({ 'page-active': props.pageActive === 'contact' })
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("a", { href: "/contact" }, "Contact", -1)
      ]), 2),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "ms-8" }, [
        _createElementVNode("button", null, "Hire me")
      ], -1))
    ]),
    _createElementVNode("div", { class: "mobile-nav xl:hidden" }, [
      _createElementVNode("button", { onClick: showGrpBtnNavMobile }, _cache[6] || (_cache[6] = [
        _createElementVNode("svg", {
          class: "h-8 w-8 text-yellow-500 primary-color",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M4 6h16M4 10h16M4 14h16M4 18h16"
          })
        ], -1)
      ]))
    ]),
    (isShowGrpBtnNavMobile.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", { onClick: hiddenGrpBtnNavMobile }, _cache[7] || (_cache[7] = [
            _createElementVNode("svg", {
              class: "h-6 w-6 text-purple-500 primary-color",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M6 18L18 6M6 6l12 12"
              })
            ], -1)
          ])),
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: _normalizeClass(["pt-4", { 'page-active': props.pageActive === 'home' }])
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("a", { href: "/" }, "Home", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["pt-4", { 'page-active': props.pageActive === 'services' }])
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("a", { href: "/services" }, "Services", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["pt-4", { 'page-active': props.pageActive === 'resume' }])
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("a", { href: "/resume" }, "Resume", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["pt-4", { 'page-active': props.pageActive === 'work' }])
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("a", { href: "/work" }, "Work", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["pt-4", { 'page-active': props.pageActive === 'contact' }])
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("a", { href: "/contact" }, "Contact", -1)
            ]), 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})