import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-slide mt-8 xl:mt-10 flex flex-col-reverse xl:flex-row" }
const _hoisted_2 = { class: "w-full xl:w-3/5 xl:text-left flex flex-col justify-between mt-12 xl:mt-0" }
const _hoisted_3 = { class: "flex mt-8 xl:mt-0 justify-center xl:justify-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_view = _resolveComponent("header-view")!

  return (_openBlock(), _createBlock(_Transition, { name: "home" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["fade-in-bottom", {'show': _ctx.showHome}])
      }, [
        _createVNode(_component_header_view, { "page-active": 'home' }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("div", null, [
              _createElementVNode("p", { class: "text-2xl" }, "Software Developer"),
              _createElementVNode("h1", { class: "text-6xl font-medium mt-2" }, "Hello I'm"),
              _createElementVNode("h1", { class: "text-6xl primary-color font-medium mt-2" }, "Kahn Potter"),
              _createElementVNode("p", { class: "mt-5" }, "Passionate about exploring and embracing new technologies.")
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "flex btn-download-cv rounded-full p-3 ps-8 pe-8 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadCV && _ctx.downloadCV(...args)))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("button", { class: "primary-color me-3" }, " DOWNLOAD CV ", -1),
                _createElementVNode("svg", {
                  class: "h-6 w-6 text-purple-500 primary-color",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  "stroke-width": "2",
                  stroke: "currentColor",
                  fill: "none",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }, [
                  _createElementVNode("path", {
                    stroke: "none",
                    d: "M0 0h24v24H0z"
                  }),
                  _createElementVNode("path", { d: "M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" }),
                  _createElementVNode("polyline", { points: "7 11 12 16 17 11" }),
                  _createElementVNode("line", {
                    x1: "12",
                    y1: "4",
                    x2: "12",
                    y2: "16"
                  })
                ], -1)
              ])),
              _cache[2] || (_cache[2] = _createElementVNode("a", {
                href: "https://gitlab.com/loc.ngvan.dev",
                target: "_blank",
                title: "Gitlab"
              }, [
                _createElementVNode("button", { class: "btn-download-cv rounded-full p-4 cursor-pointer ms-8" }, [
                  _createElementVNode("svg", {
                    class: "h-6 w-6 text-purple-500 primary-color",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    "stroke-width": "2",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", {
                      stroke: "none",
                      d: "M0 0h24v24H0z"
                    }),
                    _createElementVNode("path", { d: "M21 14l-9 7-9-7L6 3l3 7h6l3-7z" })
                  ])
                ])
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("a", {
                href: "https://www.linkedin.com/in/loc-ngvan-dev",
                target: "_blank",
                title: "LinkedIn"
              }, [
                _createElementVNode("button", { class: "btn-download-cv rounded-full p-4 cursor-pointer ms-4" }, [
                  _createElementVNode("svg", {
                    class: "h-6 w-6 text-purple-500 primary-color",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("path", { d: "M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" }),
                    _createElementVNode("rect", {
                      x: "2",
                      y: "9",
                      width: "4",
                      height: "12"
                    }),
                    _createElementVNode("circle", {
                      cx: "4",
                      cy: "4",
                      r: "2"
                    })
                  ])
                ])
              ], -1))
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full xl:w-2/5 aspect-square relative" }, [
            _createElementVNode("div", { class: "w-full h-full avatar-border rounded-full absolute" }),
            _createElementVNode("div", { class: "w-full h-full absolute" }, [
              _createElementVNode("img", {
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/avatar.JPG",
                alt: "",
                class: "w-full h-full p-2 rounded-full object-cover"
              })
            ])
          ], -1))
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex justify-between mt-16" }, [
          _createElementVNode("div", { class: "w-1/4 flex justify-center" }, [
            _createElementVNode("div", { class: "text-6xl font-extrabold" }, "+2"),
            _createElementVNode("div", { class: "flex-wrap text-left w-min ms-2 content-center" }, "Years of experience")
          ]),
          _createElementVNode("div", { class: "w-1/4 flex justify-center" }, [
            _createElementVNode("div", { class: "text-6xl font-extrabold" }, "+4"),
            _createElementVNode("div", { class: "flex-wrap text-left w-min ms-2 content-center" }, "Projects experience")
          ]),
          _createElementVNode("div", { class: "w-1/4 flex justify-center" }, [
            _createElementVNode("div", { class: "text-6xl font-extrabold" }, "+8"),
            _createElementVNode("div", { class: "flex-wrap text-left w-min ms-2 content-center" }, "Technologies known")
          ]),
          _createElementVNode("div", { class: "w-1/4 flex justify-center" }, [
            _createElementVNode("div", { class: "text-6xl font-extrabold" }, "+500"),
            _createElementVNode("div", { class: "flex-wrap text-left w-min ms-2 content-center" }, "Code commits")
          ])
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "justify-between mt-10" }, [
          _createElementVNode("div", { class: "text-2xl text-left mb-3" }, "Tech stack"),
          _createElementVNode("div", { class: "flex flex-wrap justify-center gap-4" }, [
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-center",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/vue.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-center",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/spring.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-center",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/kotlin.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/js.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/git.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/docker.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/aws.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/mongodb.png",
                alt: ""
              })
            ]),
            _createElementVNode("div", { class: "h-16" }, [
              _createElementVNode("img", {
                class: "h-full rounded-full object-contain",
                src: "https://kahnpotter.s3.ap-east-1.amazonaws.com/jira.png",
                alt: ""
              })
            ])
          ])
        ], -1))
      ], 2)
    ]),
    _: 1
  }))
}