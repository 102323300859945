<template>
  <div>
    <div id="background-1" class="background"></div>
    <div id="background-2"></div>
    <div id="background-3"></div>
    <div id="background-4"></div>
    <div id="background-5"></div>
    <div id="background-6"></div>
    <div id="background-7"></div>
    <div id="background-8"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'BackgroundAnim',
    setup() {
        onMounted(() => {
            let count = 0;
            const maxCount = 7;

            const intervalId = setInterval(() => {
                if (count < maxCount) {
                    const element = document.getElementById(`background-${count + 2}`);

                    if (element) {
                        element.classList.add('background');
                    }
                    count++;
                } else {
                    clearInterval(intervalId);
                }
            }, 50); // Thực hiện công việc A mỗi 1 giây
        });

      //   start() {
      //
      // }
    }
});
</script>

<style lang="scss">

.mobile-background {
  background-color: #1C1B22;
}

.background {
    width: 100vw;
    height: 12.5vh;

    background: linear-gradient(90deg, #1C1B22 50%, white 50%);
    background-size: 200% 100%;
    animation: slide-bg 1s forwards;
}

@keyframes slide-bg {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}
</style>
