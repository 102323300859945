<script setup lang="ts">
import {ref} from "vue";

const props = defineProps<{
  pageActive: string;
}>();

const isShowGrpBtnNavMobile = ref(false)

function showGrpBtnNavMobile() {
  isShowGrpBtnNavMobile.value = true
}

function hiddenGrpBtnNavMobile() {
  isShowGrpBtnNavMobile.value = false
}


</script>

<template>
  <div class="navigation relative flex justify-between py-6 px-2 xl:px-0">
    <div class="italic text-4xl">KahnPotter</div>

    <div class="nav-group-btn hidden xl:flex">
      <div :class="{ 'page-active': props.pageActive === 'home' }">
        <a href="/">Home</a>
      </div>

      <div :class="{ 'page-active': props.pageActive === 'services' }">
        <a href="/services">Services</a>
      </div>

      <div :class="{ 'page-active': props.pageActive === 'resume' }">
        <a href="/resume">Resume</a>
      </div>

      <div :class="{ 'page-active': props.pageActive === 'work' }">
        <a href="/work">Work</a>
      </div>

      <div :class="{ 'page-active': props.pageActive === 'contact' }">
        <a href="/contact">Contact</a>
      </div>

      <div class="ms-8">
        <button>Hire me</button>
      </div>
    </div>


    <div class="mobile-nav xl:hidden">
      <button @click="showGrpBtnNavMobile">
        <svg class="h-8 w-8 text-yellow-500 primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"/>
        </svg>
      </button>
    </div>

    <div v-if="isShowGrpBtnNavMobile" class="mobile-btn-grp">
      <button @click="hiddenGrpBtnNavMobile">
        <svg class="h-6 w-6 text-purple-500 primary-color"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>

      <div>
        <div class="pt-4" :class="{ 'page-active': props.pageActive === 'home' }">
          <a href="/">Home</a>
        </div>

        <div class="pt-4" :class="{ 'page-active': props.pageActive === 'services' }">
          <a href="/services">Services</a>
        </div>

        <div class="pt-4" :class="{ 'page-active': props.pageActive === 'resume' }">
          <a href="/resume">Resume</a>
        </div>

        <div class="pt-4" :class="{ 'page-active': props.pageActive === 'work' }">
          <a href="/work">Work</a>
        </div>

        <div class="pt-4" :class="{ 'page-active': props.pageActive === 'contact' }">
          <a href="/contact">Contact</a>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

.page-active {
  color: #ffe0a5;
  border-bottom: 2px solid var(--main-color);
}

.mobile-btn-grp {
  position: absolute;
  width: 20vh;
  height: 100vh;
  background-color: var(--secondary-color);
  right: 0;
  top: 0;
  z-index: 20;
  animation: slide 1s forwards;
}

@keyframes slide {
  0% {
    width: 0;
  }
  100% {
    width: 20vh;
  }
}

.mobile-nav {
  align-content: center;

  > button {
    vertical-align: -webkit-baseline-middle;
  }
}

.nav-group-btn > div {
  align-content: center;

  > button {
    background-color: var(--main-color);
    border-radius: 20px;
    color: var(--secondary-color);
    padding: 10px 20px;
    font-weight: bolder;
  }
}

a {
  padding: 10px 20px;
}
</style>
